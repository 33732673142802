<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Copy Contacts</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <Form @submit="handleCopyContacts" v-slot="{ errors }" ref="copy-contact-form">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Select Location</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.location }">
                                    <Field autocomplete="off" name="location" v-model="form.location_id" rules="required">
                                        <multiselect
                                            v-model="form.location_id"
                                            label="company_name"
                                            value-prop="id"
                                            :options="organizationLocations"
                                            :searchable="true"
                                            placeholder="Select"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="location" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Select segment</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.status_tab }">
                                    <Field autocomplete="off" name="status_tab" v-model="status" rules="required" label="tab">
                                        <multiselect
                                            v-model="status"
                                            :groups="true"
                                            :searchable="true"
                                            :options="locationTabs"
                                            valueProp="value"
                                            label="title"
                                            placeholder="Select segment"
                                            :group-select="false"
                                            group-label="type"
                                            group-options="items"
                                            :group-hide-empty="true"
                                        >
                                            <template v-slot:grouplabel="{ group }">
                                                <span class="status-tabs-header">{{ group.type }}</span>
                                            </template>
                                            <template v-slot:option="{ option }">
                                                <span>{{ option.title }}</span>
                                            </template>
                                        </multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="status_tab" class="text-danger" />
                            </div>
                        </div>
                        <label for="clientdetails" class="switch_option capsule_btn">
                            <h5>Client Details</h5>
                            <input type="checkbox" id="clientdetails" v-model="clientDetails" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <div v-show="clientDetails" class="edit_section">
                            <label for="profiledetails" class="switch_option capsule_btn">
                                <h5>Profile Details</h5>
                                <input type="checkbox" id="profiledetails" v-model="form.profile_details" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="completedata" class="switch_option capsule_btn">
                                <h5>Completed from Data</h5>
                                <input type="checkbox" id="completedata" v-model="form.completed_form_data" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="tags" class="switch_option capsule_btn">
                                <h5>Tags</h5>
                                <input type="checkbox" id="tags" v-model="form.tags" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="notes" class="switch_option capsule_btn">
                                <h5>Notes</h5>
                                <input type="checkbox" id="notes" v-model="form.notes" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="rewards" class="switch_option capsule_btn">
                                <h5>Rewards</h5>
                                <input type="checkbox" id="rewards" v-model="form.rewards" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="lifecycle" class="switch_option capsule_btn">
                                <h5>Lifecycle</h5>
                                <input type="checkbox" id="lifecycle" v-model="form.lifecycle" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <label for="messaging" class="switch_option capsule_btn">
                            <h5>Messaging</h5>
                            <input type="checkbox" id="messaging" v-model="messaging" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <div v-show="messaging" class="edit_section">
                            <label for="sentemails" class="switch_option capsule_btn">
                                <h5>Sent E-mails</h5>
                                <input type="checkbox" id="sentemails" v-model="form.sent_emails" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="chathistory" class="switch_option capsule_btn">
                                <h5>Chat History</h5>
                                <input type="checkbox" id="chathistory" v-model="form.chat_history" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <label for="automation" class="switch_option capsule_btn">
                            <h5>Assets and Automation</h5>
                            <input type="checkbox" id="automation" v-model="automation" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <div v-show="automation" class="edit_section">
                            <label for="activefiles" class="switch_option capsule_btn">
                                <h5>Active Files</h5>
                                <input type="checkbox" id="activefiles" v-model="form.active_files" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="activeplaybooks" class="switch_option capsule_btn">
                                <h5>Active Playbooks</h5>
                                <input type="checkbox" id="activeplaybooks" v-model="form.active_playbooks" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="activepages" class="switch_option capsule_btn">
                                <h5>Active Pages</h5>
                                <input type="checkbox" id="activepages" v-model="form.active_pages" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="activeforms" class="switch_option capsule_btn">
                                <h5>Active Forms</h5>
                                <input type="checkbox" id="activeforms" v-model="form.active_forms" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="activetask" class="switch_option capsule_btn">
                                <h5>Active Tasks</h5>
                                <input type="checkbox" id="activetask" v-model="form.active_tasks" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="activepipeline" class="switch_option capsule_btn">
                                <h5>Active Pipelines</h5>
                                <input type="checkbox" id="activepipeline" v-model="form.active_pipelines" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="activeprogress" class="switch_option capsule_btn">
                                <h5>Active Client Tracking</h5>
                                <input type="checkbox" id="activeprogress" v-model="form.active_progress_tracking" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="activehabit" class="switch_option capsule_btn">
                                <h5>Active Habit Tracking</h5>
                                <input type="checkbox" id="activehabit" v-model="form.active_habit_tracking" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="activejourney" class="switch_option capsule_btn">
                                <h5>Active Journey</h5>
                                <input type="checkbox" id="activejourney" v-model="form.active_journeys" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="activesequence" class="switch_option capsule_btn">
                                <h5>Active Sequences</h5>
                                <input type="checkbox" id="activesequence" v-model="form.active_sequences" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <label for="portalogin" class="switch_option capsule_btn">
                            <h5>Send New Portal Login Details</h5>
                            <input type="checkbox" id="portalogin" v-model="form.portal_login_details" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <label for="notifyclient" class="switch_option capsule_btn">
                            <h5>Notifications to Client</h5>
                            <input type="checkbox" id="notifyclient" v-model="form.notification_to_client" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <div v-show="form.notification_to_client">
                            <div class="edit_section">
                                <sending-method v-model="form.notification.sending_method" />
                            </div>
                            <ul class="innertab_sec mt-5">
                                <li @click="TempTab = 'EmailComp'" :class="{active: TempTab === 'EmailComp'}">E-mail</li>
                                <li @click="TempTab = 'SmsComp'" :class="{active: TempTab === 'SmsComp'}">SMS</li>
                            </ul>
                            <div class="tab_content" v-show="TempTab == 'EmailComp'">
                                <contact-email-component v-model="emailContent" :errors="errors" ref="email-component" />
                            </div>
                            <div class="tab_content" v-show="TempTab == 'SmsComp'">
                                <contact-sms-component v-model="form.notification.sms_message" ref="sms-component"/>
                            </div>
                        </div>
                        <div class="action_wpr mt-5">
                            <button :disabled="bulkActionLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="bulkActionLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="bulkActionLoader"></i>{{ bulkActionLoader ? ' Copying' : 'Copy' }}</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'
    import { defineAsyncComponent } from 'vue'

    const ContactEmailComponent = defineAsyncComponent(() => import('@/pages/contact/components/EmailComponent'))
    const ContactSmsComponent = defineAsyncComponent(() => import('@/pages/contact/components/SmsComponent'))

    export default {
        name: 'CopyContact',

        data () {
            return {
                form: {
                    contacts: [],
                    location_id:'',
                    status_tab: [],
                    profile_details: 1,
                    completed_form_data: 1,
                    tags: 1,
                    notes: 1,
                    rewards: 1,
                    lifecycle: 1,
                    sent_emails: 0,
                    chat_history: 0,
                    active_files: 0,
                    active_playbooks: 0,
                    active_pages: 0,
                    active_forms: 0,
                    active_tasks: 0,
                    active_pipelines: 0,
                    active_progress_tracking: 0,
                    active_habit_tracking: 0,
                    active_journeys: 0,
                    active_sequences: 0,
                    portal_login_details: 0,
                    notification_to_client: 0,
                    notification: {
                        sending_method: 1,
                        email_subject: '',
                        email_message: '',
                        sms_message: '',
                        display_logo: 0,
                        email_logo: '',
                        display_signature: 0,
                        signature_id: 0,
                    },
                },
                status: [],
                clientDetails: 0,
                messaging: 0,
                automation: 0,
                TempTab: 'EmailComp',
                emailContent: {
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                locations: [],
            }
        },

        props: {
            modelValue: Boolean,
            contacts: Array,
        },

        emits: ['update:modelValue'],

        components: {
            Form,
            Field,
            ErrorMessage,
            ContactEmailComponent,
            ContactSmsComponent,
        },

        watch: {
            'form.location_id'() {
                const vm = this;
                vm.locations = [];

                if (vm.form.location_id) {
                    vm.getLocationTabs(vm.form.location_id).then(() => {
                        vm.locations = vm.locationTabs
                    });
                }
            },

            emailContent: {
                handler (content) {
                    const vm = this;

                    vm.form.notification.email_subject      = content.subject;
                    vm.form.notification.email_message      = content.email;
                    vm.form.notification.display_logo       = content.show_email_logo;
                    vm.form.notification.email_logo         = content.email_logo;
                    vm.form.notification.display_signature  = content.is_signature;
                    vm.form.notification.signature_id       = content.signature_id;
                },
                deep: true
            },

            status (val) {
                const vm = this;

                if (val) {
                    const status = Object.values(vm.statusList).find(status => status.value ===  val);

                    vm.form.status_tab = status;
                }
            },
            modelValue(val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: {
            ...mapState({
                organizationLocations: state => state.authModule.organizationLocations,
                locationTabs: state => state.authModule.locationTabs,
                statusList: state => state.contactModule.statusList,
                bulkActionLoader: state => state.contactModule.bulkActionLoader,
            }),
        },

        mounted () {
            const vm = this;

            if (vm.organizationLocations.length == 0) {
                vm.getOrganizationLocations();
            }

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                getOrganizationLocations: 'authModule/getOrganizationLocations',
                getLocationTabs: 'authModule/getLocationTabs',
                copyContactToLocation: 'contactModule/copyContactToLocation',
            }),

            closeModal () {
                const vm = this;

                vm.resetForm();
                vm.$emit('update:modelValue', false);
            },

            getDefaultMessage (type) {
                let content = '';

                if (type == 'email-subject') {
                    content = 'You have been added to a new location';
                } else if (type == 'email-content') {
                    content = '<p>Hi {{ first_name }}</p>';
                    content += '<p>{{company_owner}} from {{company_name}} has recently added you to the {{location_name}} location.</p>';
                    content += '<p>You will now be able to receive messages from them and should also able to switch between your assigned locations from your client portal profile settings.<br /><br /></p>';
                    content += '<p>Thanks,</p>';
                    content += '<p>{{company_name}}</p>';
                } else if (type == 'sms-content') {
                    content = '{{company_owner}} from {{company_name}} has recently added you to the {{location_name}} location.\r\n';
                    content += 'You will now be able to receive messages from them and should also able to switch between your assigned locations from your client portal profile settings.\r\n';
                    content += 'Thanks,\r\n';
                    content += '{{company_name}}';
                }

                return content;
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    contacts: [],
                    location_id:'',
                    status_tab: [],
                    profile_details: 1,
                    completed_form_data: 1,
                    tags: 1,
                    notes: 1,
                    rewards: 1,
                    lifecycle: 1,
                    sent_emails: 0,
                    chat_history: 0,
                    active_files: 0,
                    active_playbooks: 0,
                    active_pages: 0,
                    active_forms: 0,
                    active_tasks: 0,
                    active_pipelines: 0,
                    active_progress_tracking: 0,
                    active_habit_tracking: 0,
                    active_journeys: 0,
                    active_sequences: 0,
                    portal_login_details: 0,
                    notification_to_client: 0,
                    notification: {
                        sending_method: 1,
                        email_subject: '',
                        email_message: '',
                        sms_message: '',
                        display_logo: 0,
                        email_logo: '',
                        display_signature: 0,
                        signature_id: 0,
                    },
                };

                vm.emailContent.subject = vm.getDefaultMessage('email-subject');
                vm.emailContent.email   = vm.getDefaultMessage('email-content');
                vm.form.notification.sms_message = vm.getDefaultMessage('sms-content');

                vm.clientDetails  = 0;
                vm.messaging  = 0;
                vm.automation = 0;
            },

            handleCopyContacts (params, { setFieldError }) {
                const vm = this;

                vm.form.contacts = vm.contacts;
                vm.form.setFieldError = setFieldError;
                vm.form.closeModal = vm.closeModal;

                vm.copyContactToLocation(vm.form);
            },
        },
    }
</script>
